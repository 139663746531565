var data = {
  body: `<g id="Artboard1" transform="matrix(0.992373,0,0,1.01742,-1.20204,1.4848)">
    <rect x="1.211" y="-1.459" width="251.922" height="226.061" style="fill:none;"/>
    <g transform="matrix(1.00769,0,0,0.982876,-257.798,-277.012)">
        <path d="M482.719,505.976L281.347,505.976C268.084,505.976 257.33,495.36 257.33,482.267L257.33,308.44C257.33,295.344 268.084,284.731 281.347,284.731L482.719,284.731C495.981,284.731 506.732,295.344 506.737,308.44C506.732,308.44 506.732,482.267 506.732,482.267C506.732,495.359 495.982,505.976 482.719,505.976ZM427.423,431.21L398.422,431.21L387.024,395.201L398.575,359.397L427.381,359.397C430.927,367.398 438.943,372.998 448.242,372.998C460.825,372.998 471.059,362.762 471.059,350.18C471.059,337.597 460.825,327.363 448.242,327.363C439.382,327.363 431.692,332.443 427.913,339.841L391.56,339.841C387.375,339.841 383.633,342.526 382.293,346.489L369.11,385.525L336.804,385.525C333.314,377.386 325.228,371.673 315.825,371.673C303.245,371.673 293.008,381.907 293.008,394.489C293.008,407.069 303.245,417.303 315.825,417.303C324.585,417.303 332.201,412.34 336.023,405.081L368.956,405.081L382.143,444.117C383.48,448.082 387.223,450.768 391.407,450.766L427.863,450.766C431.62,458.221 439.342,463.345 448.242,463.345C460.825,463.345 471.059,453.111 471.059,440.528C471.059,427.949 460.825,417.714 448.242,417.714C438.982,417.714 430.996,423.262 427.423,431.21ZM448.242,451.122C442.401,451.122 437.651,446.369 437.651,440.527C437.651,434.686 442.401,429.936 448.242,429.936C454.084,429.936 458.837,434.686 458.837,440.527C458.837,446.369 454.084,451.122 448.242,451.122ZM315.825,405.081C309.983,405.081 305.234,400.328 305.234,394.49C305.234,388.648 309.983,383.896 315.825,383.896C321.666,383.896 326.42,388.648 326.42,394.49C326.42,400.328 321.666,405.081 315.825,405.081ZM448.242,339.585C454.084,339.585 458.837,344.338 458.837,350.18C458.837,356.021 454.084,360.775 448.242,360.775C442.401,360.775 437.651,356.02 437.651,350.18C437.651,344.338 442.401,339.585 448.242,339.585Z" style="fill-rule:nonzero;" fill="currentColor" />
    </g>
</g>`,
  width: 250,
  height: 230,
};

export default data;
