import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'grommet';
import { Link } from 'gatsby';
import lukashorakLogo from '../images/logo_large.svg';
import { getThemeColor } from '../theme';
import WebNavigation from './WebNavigation';
import SocialIconContacts from './SocialIconContacts';
import Headroom from 'react-headroom';
import styled from 'styled-components';
import media from 'styled-media-query';

const Logo = styled.img`
  max-height: 35px;

  ${media.lessThan('medium')`
    max-height: auto;
    object-fit: contain;
  `}
`;

const renderLogo = () => (
  <Link
    to="/"
    css={`
      display: flex;
      width: 100%;
    `}
    alt="Lukas Horak Homepage"
  >
    <Logo src={lukashorakLogo} alt="Lukas Horak Logo" />
  </Link>
);

const MobileNav = styled(Box)`
  display: none;
  width: 100%;

  ${media.lessThan('medium')`
      display: flex;
  `}
`;

const NavigationContainer = styled(Box)`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: center;
  padding: 0 3px 0 3px;

  ${media.lessThan('medium')`
      display: none;
  `}
`;

const SocialIconsContainer = styled(Box)`
  display: flex;
  box-sizing: border-box;
  outline: none;
  align-items: flex-end;
  flex-direction: column;
  width: 25%;
  padding: 0;
  font-size: 1.5rem;

  ${media.lessThan('medium')`
    font-size: 1.4rem;
    flex-shrink: 0;
  `}
`;

const LogoContainer = styled(Box)`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.lessThan('medium')`
    width: 100%;
    flex-shrink: 1;
    flex-basis: 50%;
    justify-content: flex-start;
  `}
`;

const Header = () => {
  return (
    <Headroom style={{ zIndex: 999 }}>
      <Box
        width="full"
        css={`
          background-color: ${getThemeColor('brand-dark-blue')};
          color: ${getThemeColor('brand-light-gray')};
          padding: 12px;
          margin: 0;

          ${media.lessThan('medium')`
            padding: 6px;
            flex-direction: column;
            justify-content: flex-start;
          `}
        `}
      >
        <Box
          direction={'row'}
          align={'center'}
          justify={'between'}
          width="xxlarge"
          css={`
            margin: 0 auto;
          `}
        >
          <LogoContainer>{renderLogo()}</LogoContainer>

          <NavigationContainer
            pad={{ vertical: 'xxsmall', horizontal: '0px' }}
            direction={'row'}
            justify={'center'}
            width={'50%'}
          >
            <WebNavigation />
          </NavigationContainer>

          <SocialIconsContainer>
            <SocialIconContacts />
          </SocialIconsContainer>
        </Box>
        <MobileNav>
          <WebNavigation />
        </MobileNav>
      </Box>
    </Headroom>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
