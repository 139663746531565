import React from 'react';
import NavigationContainer from './NavigationContainer';
import NavLink from './NavLink';

const WebNavigation = props => {
  return (
    <>
      <NavigationContainer as="nav" {...props}>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about-me/">About</NavLink>
        <NavLink to="/resume/">Resume</NavLink>
        {/* <NavLink to="/blog/" partiallyActive={true}>
          Blog
        </NavLink> */}
      </NavigationContainer>
    </>
  );
};

export default WebNavigation;
