import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme, { getThemeColor } from '../theme';

const StyledNavLink = styled(Link)`
  color: ${getThemeColor('brand-light-gray')};
  padding: 0.7rem 0.6rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.9rem;
  transition: all 300ms ease-out;
  display: flex;
  :hover {
    color: ${theme.global.colors.brand};
  }
  &.active {
    color: ${theme.global.colors.brand};
  }
`;

export const NavLink = props => {
  return (
    <StyledNavLink {...props} activeClassName="active">
      {props.children}
    </StyledNavLink>
  );
};

export default NavLink;
