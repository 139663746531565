import styled from 'styled-components';
import theme from '../theme';
import { Box } from 'grommet';

const NavigationContainer = styled(Box)`
  font-size: 1rem;
  color: ${theme.global.colors['dark-1']};
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction || 'row'};
`;

export default NavigationContainer;
