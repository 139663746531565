/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site, ogImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        ogImage: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "og_image_large_text.jpg" }
        ) {
          publicURL
        }
      }
    `
  );

  // const schemaOrgJSONLD = [
  //   {
  //     '@context': 'http://schema.org',
  //     '@type': 'WebSite',
  //     url: 'https://lukashorak.com',
  //     name: title,
  //   },
  //   {
  //     '@context': 'http://schema.org',
  //     '@type': 'Person',
  //     name: 'Lukas Horak',
  //     givenName: 'Lukas',
  //     familyName: 'Horak',
  //     nationality: 'Czech',
  //     email: 'hello@lukashorak.com',
  //     url: 'https://lukashorak.com',
  //     jobTitle: 'Front-end Manager, Front-end Tech Lead',
  //     alumniOf: 'Czech Technical University in Prague',
  //     gender: 'male',
  //   },
  // ];

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage.publicURL,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: ogImage.publicURL,
        },
      ].concat(meta)}
    ></Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
