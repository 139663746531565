import React, { useContext } from 'react';
import { Box, Text, Anchor, Image, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import media from 'styled-media-query';
import { getThemeColor } from '../theme';
import lukashorakLogo from '../images/logo_large.svg';
import WebNavigation from './WebNavigation';
import SocialIconContacts from './SocialIconContacts';
import { Link } from 'gatsby';

const FooterWrapper = styled(Box)`
  background: ${getThemeColor('brand-dark-blue')};
  color: ${getThemeColor('brand-light-gray')};
`;

const NavigationContainer = styled(Box)`
  flex-basis: ${1 / 3};
  margin: 0;
  padding: 12px 0;
  display: flex;
  align-items: end;

  ${media.lessThan('medium')`
      flex-basis: 100%;
      align-items: center;
      margin: 0 auto;
    `}
`;

function Footer() {
  const screenSize = useContext(ResponsiveContext);
  return (
    <FooterWrapper
      as="footer"
      direction="column"
      border="top"
      width="full"
      pad="small"
    >
      <Box
        width="xxlarge"
        margin={{ horizontal: 'auto' }}
        align="center"
        direction={
          screenSize === 'small' || screenSize === 'medium' ? 'column' : 'row'
        }
        pad="medium"
        justify="around"
      >
        <Box
          align={
            screenSize === 'small' || screenSize === 'medium'
              ? 'center'
              : 'start'
          }
          justify={
            screenSize === 'small' || screenSize === 'medium'
              ? 'center'
              : 'start'
          }
          css={`
            padding: 12px 0;
            order: ${screenSize === 'small' || screenSize === 'medium' ? 1 : 0};
            text-align: ${screenSize === 'small' || screenSize === 'medium'
              ? 'center'
              : 'inherit'};
          `}
        >
          <Box width="small">
            <Link to="/" title="Home page">
              <Image
                src={lukashorakLogo}
                alt="Lukas Horak Logo"
                a11yTitle="Lukas Horak Logo"
              />
            </Link>
          </Box>
          <Text size="small">Full-stack JavaScript Developer</Text>
          <Text size="small">
            I create stuff with{' '}
            <span role="img" aria-label="heart">
              ❤️
            </span>{' '}
            in React and NodeJS
          </Text>

          <Box>
            <Anchor href="mailto:hello@lukashorak.com">
              hello@lukashorak.com
            </Anchor>
          </Box>
        </Box>
        <Box
          align="center"
          justify="start"
          css={`
            font-size: 2rem;
            padding: 12px 0;
          `}
        >
          <SocialIconContacts />
        </Box>
        <NavigationContainer>
          <WebNavigation direction="row" />
        </NavigationContainer>
      </Box>
      <Box
        width="xxlarge"
        margin={{ horizontal: 'auto' }}
        align="center"
        pad="medium"
      >
        <Text size="small" textAlign="center">
          Copyright &copy; {new Date().getFullYear()} Lukáš Horák. All rights
          reserved. JavaScript&nbsp;full&#8209;stack&nbsp;developer
        </Text>
      </Box>
    </FooterWrapper>
  );
}

Footer.propTypes = {};

export default Footer;
