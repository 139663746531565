import React from 'react';
import { Anchor } from 'grommet';
import stackshareIcon from '../custom-icons/stackshare';
import linkedinIcon from '@iconify/icons-fa-brands/linkedin-in';
import twitterIcon from '@iconify/icons-fa-brands/twitter';
import behanceIcon from '@iconify/icons-fa-brands/behance';
import githubIcon from '@iconify/icons-fa-brands/github';
import { InlineIcon } from '@iconify/react';
import styled from 'styled-components';
// import linkedinIcon from '@iconify/icons-simple-icons/linkedin';
// import twitterIcon from '@iconify/icons-simple-icons/twitter';
// import githubIcon from '@iconify/icons-simple-icons/github';
// import mailIcon from '@iconify/icons-fa-solid/envelope';

import { getThemeColor } from '../theme';
import LinksList from './LinksList';
import LinksListItem from './LinksListItem';

const IconLink = styled(Anchor)`
  color: ${getThemeColor('brand-light-gray')};
  transition: color 350ms ease-out;
  display: flex;
  flex-direction: row;
  font-size: inherit;

  &:hover {
    color: ${getThemeColor('brand')};
  }

  &:focus,
  &:active {
    box-shadow: none;
  }
`;

const items = [
  {
    icon: linkedinIcon,
    link: 'https://www.linkedin.com/in/lhorak',
    title: 'Lukas Horak on Linkedin',
  },
  {
    icon: githubIcon,
    link: 'https://github.com/lhorak',
    title: 'Lukas Horak on GitHub',
  },
  {
    icon: stackshareIcon,
    link: 'https://stackshare.io/lhorak',
    title: 'My dev stack on Stackshare',
  },
  {
    icon: twitterIcon,
    link: 'https://twitter.com/lhorak_cz',
    title: 'Lukas Horak on Twitter',
  },
  {
    icon: behanceIcon,
    link: 'https://www.behance.net/lukashorak',
    title: 'Lukas Horak on Behance',
  },
  // {
  //   icon: mailIcon,
  //   link: 'mailto:hello@lukashorak.com',
  //   title: 'Email me at hello@lukashorak.com',
  // },
];

const SocialIconContacts = ({ color, size }) => {
  return (
    <LinksList>
      {items.map(({ icon, link, title }, index) => (
        <LinksListItem key={index}>
          <IconLink
            href={link}
            title={title}
            target="_blank"
            rel="noopener"
            alt={title}
          >
            <InlineIcon
              icon={icon}
              css={`
                font-size: inherit;
              `}
              alt={title}
            />
          </IconLink>
        </LinksListItem>
      ))}
    </LinksList>
  );
};

SocialIconContacts.defaultProps = {
  size: '1.5rem',
  color: getThemeColor('brand'),
};

export default SocialIconContacts;
